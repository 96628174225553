import { Injectable } from '@angular/core';
import { Product } from '../interfaces/product';
import { ControllersService } from './controllers.service';
import { ResolverService } from './resolver.service';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  skeleton: any;
  getBagItems() {
    return this.bagItems;
  }

  private bag: Array<any> = [];
  private bagItems: number = 0;
  public bagId: string = '';
  public bagLastLength: any;
  constructor(
    public resolver: ResolverService,
    public controller: ControllersService,
    public locations:Location
  ) {
    this.getTotalBag();
  }
  public async addToBag(bagItem?: Product) {
    if (bagItem) {
      this.bag.push(bagItem);
    }
    let totalItems = { items: this.bag };
    let endpoint = this.bagItems
      ? this.resolver.updateCart(this.bagId, totalItems)
      : this.resolver.createCart(totalItems);

    try {
      const data = await endpoint.toPromise();
      this.bagItems = this.bag.length;
      await this.getTotalBag();
    } catch (err) {
      this.controller.presentAlert(err.error.error.message);
    }
  }

  public removeFromBag(index: number, productIndex: number = -1, prevItem?) {
    this.bag.splice(index, 1);
    this.addToBag();
  }

  public async getTotalBag() {
    let urlPath = this.locations.path();
    if (urlPath === '') {
      this.skeleton = new BehaviorSubject<boolean>(true);
    } else {
      this.skeleton = new BehaviorSubject<boolean>(false);
    }
    this.bagId = ''
    try {
      const resp: any = await this.resolver.getCart().toPromise();
      if (resp.length) {
        let data = resp[resp.length - 1];
        this.bagLastLength = resp[0].items.length - 1;
        this.bag = data.items;
        this.bagId = data.id;
        this.bagItems = this.bag.length;
      } else {
        this.bag = [];
      }
    } catch (err) {
      this.controller.presentAlert(err.error.error.message);
    }
  }
  public getBag() {
    
    window.localStorage.setItem('cartData', JSON.stringify(this.bag));
    return this.bag;
  }
  public getBagCount() {
    return this.bag.length;
  }
  updateBag(product: any, productIndex: number) {
    this.bag[productIndex] = product;
    this.addToBag();
  }

  public saveBag() {}
  public setBag(bag) {
    this.bag = bag;
  }
  public emptyBag() {
    this.resolver
      .deleteCart(this.bagId)
      .toPromise()
      .then((data) => {
        this.bagItems = 0;
        this.bagId = '';
        this.bag.forEach((item, index) => {
          this.bag.splice(index, 1);
        });
      });
  }
  emptyBagCOD() {
    this.bagItems = 0;
    this.bagId = '';
    this.bag = [];
      }
}
